import { Component, computed, CUSTOM_ELEMENTS_SCHEMA, effect, signal} from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatStep, MatStepHeader, MatStepper, MatStepperNext, MatStepperPrevious } from '@angular/material/stepper';
import { MatError, MatFormField, MatFormFieldControl, MatLabel } from '@angular/material/form-field';
import { CheckIcon } from 'primeng/icons/check';
import { CommonModule } from '@angular/common';
import { MainHeaderComponent } from '../shared/main-header/main-header.component';
import { MatOption } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { Library } from '../../models/library';
import { LibraryService } from '../../services/library.service';
import { SendRecognitionBottomsheetComponent } from '../shared/send-recognition-bottomsheet/send-recognition-bottomsheet.component';
import { MatSpinner } from '@angular/material/progress-spinner';


@Component({
  selector: 'app-library-objects',
  standalone: true,
  imports: [MatStepper, MatStep, ReactiveFormsModule, RouterLink, MatLabel, CheckIcon, CommonModule, MatFormField, MatError, MainHeaderComponent, MatOption, MatSelectModule, MatInputModule, MatStepperPrevious, MatStepperNext, CheckIcon, MatIconModule, MatIcon, MatButtonModule, MatSpinner],
  templateUrl: './library-objects.component.html',
  styleUrl: './library-objects.component.css'
})
export class LibraryObjectsComponent {
  isLoading: boolean = true;

  readonly panelOpenState = signal(false);
  oculto = false;
  libraryId!: number;
  libraryData: any;
  showConfig = false;
  isLinear = false;y: any[] = []; 
  libraryCategory: any [] = [];
  libraryBrand: any[] = [];
  libraryEan: any[] = []; 
 
   private default_library: Partial<Library> = {
    "name": " ",
    "label": " ",
    "description": " ",
    "thumbnail": " ",
    "libraryBrand": {
      "name": " ",
    },
    "libraryCategory": {
      "name": " ",
    },
    "libraryEan": {
      "ean": 0,
    }
  
  };
  
  private library_subject = new BehaviorSubject<Library | Partial<Library> | undefined>(this.default_library);
  library$: Observable<Library | Partial<Library> | undefined> = this.library_subject.asObservable();

  titleheader: string = 'producto';

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private libraryService: LibraryService, private router: Router, private _snackBar: MatSnackBar,private _bottomSheet: MatBottomSheet,){

  }
  
  formConfig = [
    { controlName: 'name', label: 'Nombre', type: 'text', width: '100%', margin: '' },
    { controlName: 'label', label: 'Etiqueta', type: 'textarea', width: '100%', margin: '' },
    { controlName: 'description', label: 'Descripcion', type: 'textarea', width: '100%', margin: '' },
    { controlName: 'thumbnail', label: 'Enlace', type: 'textarea', width: '100%', margin: '' },
 /*    { controlName: 'libraryBrand', label: 'Marca', type: 'textarea', width: '100%', margin: '' },
    { controlName:'libraryCategory', label: 'Categoria', type: 'textarea', width: '100%', margin: '' },
    { controlName: 'libraryEan', label: 'Ean', type: 'textarea', width: '100%', margin: '' }, */
  ]
 
  libraryForm: FormGroup = this.fb.group({
    name: ['', [Validators.required, Validators.minLength(3)]],
    label: ['', [Validators.required, Validators.minLength(3)]],
    description: [''],
    thumbnail: ['', [Validators.pattern('https?://.*\\.(com|co|net|org|edu|gov|io|info|png)$')]],
    libraryBrand: ['', Validators.required], 
    libraryCategory: ['', Validators.required], 
    libraryEan: ['', Validators.required],
  });


  async ngOnInit(): Promise<void> {
    this.libraryService.loadLibraryStore()
     this.libraryService.library$.subscribe((library) => {
      
      if (library.length === 0) {
        console.log("Datos aún no disponibles.");
      } else {
        this.libraryBrand = library.map(lib => lib.libraryBrand || { name: '' });
        this.libraryCategory = library.map(lib => lib.libraryCategory || { name: '' });
        this.libraryEan = library.map(lib => lib.libraryEan|| { ean: 0 });

      }
    });
    this.libraryId = +this.route.snapshot.paramMap.get('id')!;
    console.log('ID de la biblioteca:', this.libraryId);
    if (this.libraryId) {
      /* this.getTask() */
      this.titleheader = 'Editando producto'
      this.refreshlibraries()
    } else {
      this.titleheader = 'Creando producto'
      console.log('Creando nuevo')
  
    }
  }


  
  createlibraries(libraryData: Partial<Library>) {
    this.libraryService.createLibrary(libraryData).subscribe({
      next: () => {
        
        this._snackBar.open('producto creado con éxito', 'Cerrar', { duration: 3000 });
      },
      error: (err) => {
        if (err) {
          // Aquí mostramos el mensaje que viene del backend
          this._snackBar.open(` ${err.error.message}`, 'Cerrar', { duration: 3000 });
        } else {
          this._snackBar.open('Error al crear el objeto', 'Cerrar', { duration: 3000 });
        } 
      } 
    });
  }
  
  updatelibraries(librariesData: Partial<Library>) {
    this.libraryService.updateLibrary(librariesData, this.libraryId).subscribe({
      next: () => {
        this._snackBar.open('producto actualizado con éxito', 'Cerrar', { duration: 3000 });
      },
      error: () => this._snackBar.open('Error al actualizar el objeto', 'Cerrar', { duration: 3000 })
    });
  }
  
  getIdFromName(value: string): number {
    const selectedBrand = this.libraryBrand.find(brand => brand.name === value);
    return selectedBrand ? selectedBrand.library_brand_id : 0;
  }
  
  getIdFromCategoryName(value: string): number {
    const selectedCategory = this.libraryCategory.find(category => category.name === value);
    return selectedCategory ? selectedCategory.library_category_id : 0;
  }
  
  getIdFromValue(value: number): number {
    console.log('Lista de EANs:', this.libraryEan); 
    const selectedEan = this.libraryEan.find(ean => ean.ean === value);
    if (value === 0) {
      return 1; // Asumiendo que el ID es 1
    }
    return selectedEan ? selectedEan.libraries_ean_id : 0;
  }
  
  creating?: boolean = true
  for_save: boolean = false


  refreshlibraries() {
  this.libraryService.getLibraryById(this.libraryId).subscribe(library => {
      this.default_library = library
      this.library_subject.next(this.default_library)
      

      if (library) {
        this.creating = false
        this.libraryForm.patchValue({
          name: library.name,
          label: library.label,
          description: library.description,
          thumbnail: library.thumbnail,
          libraryBrand: library.libraryBrand.name,
          libraryCategory: library.libraryCategory.name,
          libraryEan: library.libraryEan.ean
        })
        this.isLoading = false
        this.libraryData = library
      }
    })
  } 
  
  savelibraries() {
    if (this.libraryForm.valid) {
      const formValue = this.libraryForm.value;
  
      const libraryData: Partial<Library> = {
        name: formValue.name,
        label: formValue.label,
        description: formValue.description,
        library_brand_id: this.getIdFromName(formValue.libraryBrand),  
        library_category_id: this.getIdFromCategoryName(formValue.libraryCategory), 
        library_ean_id: this.getIdFromValue(formValue.libraryEan),
        thumbnail: formValue.thumbnail || undefined,
        
      };
      console.log('Datos enviados al backend:', libraryData);  
      if (this.libraryId) {
        this.updatelibraries(libraryData);
      } else {
        this.createlibraries(libraryData);
      }
    } else {
      console.log('Formulario no válido');
    }
  }


  
}
