export const environment = {
    production: false,
    apiURL:'',
    api_backend: 'https://recognitionadminapidev.lucro.com.co',
    // api_recognition: 'https://recognitiondev.lucro.com.co',
    api_recognition: 'https://recognitiondev.lucro.com.co',
    firebase: { 
        "projectId": "lucro-recognition-system", 
        "appId": "1:569397935990:web:1457b341878090f8c24457", 
        "storageBucket": "lucro-recognition-system.appspot.com", 
        "apiKey": "AIzaSyDOxiDIqJHE3StAdSg98krbMZ_LYH3a2L0", 
        "authDomain": "lucro-recognition-system.firebaseapp.com", 
        "messagingSenderId": "569397935990", 
        "measurementId": "G-VWK9VS78MQ" 
      },
    client: 'cb1496cd-92af-4b14-abeb-230ee3de9f08',
    key: 'ef8d31haa860e646t485ht99a99ha03e74d46ded53'
};