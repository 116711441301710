
<div class="lc_container">
    @if(show_preview){
    <div class="lc_picture_preview_container">
        <div class="lc_picture_preview_image">
            <img [src]="webcamImage?.imageAsDataUrl" />
        </div>
        <div class="lc_picture_preview_buttons">
            <button class="lc_gray_button" (click)="approve_image()" mat-icon-button aria-label="Aceptar">
                <mat-icon>check</mat-icon>
            </button>
            <button class="lc_gray_button" (click)="refuse_image()" mat-icon-button aria-label="Cancelar">
                <mat-icon>cancel</mat-icon>
            </button>
        </div>

    </div>
    }

    <div class="lc_layout">
        <div class="lc_header">
            <button class="lc_camera_close lc_white_button" mat-mini-fab (click)="close();" aria-label="Cerrar">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="lc_camera_view_container">
            <webcam [height]="height" [width]="width" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
                *ngIf="showWebcam" [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
                [captureImageData]="captureImageData" [videoOptions]="videoOptions" [imageQuality]="1"
                (cameraSwitched)="cameraWasSwitched($event)" (initError)="handleInitError($event)"></webcam>

            <div class="lc_snapshots_container">
                @for (file of files; track file; let index = $index) {
                <div class="lc_snapshots_container_item">
                    <img width="70" height="70" [src]="file" />
                    <button (click)="delete(index)" class="material-symbols-outlined lc_button_delete_picture"
                        mat-icon-button aria-label="Eliminar">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
                }
            </div>

        </div>
        <div class="lc_controls_container">
            <button class="lc_gray_button" (click)="showNextWebcam(false)" mat-icon-button aria-label="Enviar">
                <mat-icon>cameraswitch</mat-icon>
            </button>
            @if(showWebcam){
            <button class="lc_camera_button" (click)="triggerSnapshot();" mat-fab aria-label="Toma una foto">
            </button>
            }
            <button class="lc_gray_button" (click)="send();" mat-icon-button aria-label="Enviar" [disabled]="!files.length">
                <mat-icon>send</mat-icon>
            </button>
        </div>

    </div>

</div> 