<section>
    <button class="red" mat-icon-button [matMenuTriggerFor]="networkMenu"
        [ngStyle]="{ color: (networkStatus$ | async)?.isOnline ? 'rgb(0, 203, 124)' : 'rgb(256, 72, 72)' }">
        <mat-icon>{{ (networkStatus$ | async)?.isOnline ? 'wifi' : 'wifi_off' }}</mat-icon>
    </button>

    <mat-menu #networkMenu="matMenu">
        <div class="red-info" *ngIf="networkStatus$ | async as networkStatus">
            <p><strong>Estado:</strong> {{ networkStatus.isOnline ? 'En línea' : 'Desconectado' }}</p>
            <div *ngIf="networkStatus.isOnline">
                <p><strong>Tipo de conexión:</strong> {{ networkStatus.connectionInfo.effectiveType || 'unknown' }}
                </p>
                <p><strong>Velocidad de descarga:</strong> {{ networkStatus.connectionInfo.downlink || 0 }} Mbps</p>
                <p><strong>RTT:</strong> {{ networkStatus.connectionInfo.rtt || 0 }} ms</p>
            </div>
            <div *ngIf="!networkStatus.isOnline">
                <p>No hay información de red disponible.</p>
            </div>
        </div>
    </mat-menu>
    <app-main-header [name_main]="titleheader">
        @if(isLargeScreen$ | async){
        <section class="btns">
            <button mat-button routerLink="/playground/models">Modelos</button>
            <button mat-button routerLink="/playground/task" routerLinkActive="activebuttonb">Tareas</button>
            <!-- <button mat-button routerLink="/playground/rules" [disabled]="true">Reglas</button> -->
        </section>
        }@else{
        <button mat-button mat-stroked-button class="btns-nav" [matMenuTriggerFor]="menu">
            <span class="btns-nav-menu">
                {{ router.url.includes('/task') ? 'Tareas' : 'Módelos' }}
                <mat-icon class="material-symbols-outlined" style="margin-right: 0;"> arrow_drop_down </mat-icon>
            </span>
        </button>
        <mat-menu #menu="matMenu">
            <button routerLink="/playground/models" mat-menu-item>Módelos</button>
            <button routerLink="/playground/task" mat-menu-item>Tareas</button>
        </mat-menu>
        }
    </app-main-header>
    <div class="ctn-aut">
        <p-autoComplete id="search-input" [(ngModel)]="selectedItems" (ngModelChange)="onSelect($event)"
            [suggestions]="filteredTasks" (completeMethod)="search($event)" field="view" [dropdown]="true"
            placeholder="Buscar tareas de reconocimiento">
            <ng-template let-task pTemplate="item">
                {{ task.view }}
            </ng-template>
        </p-autoComplete>
        <span *ngIf="selectedItems === null || !(hasSelected$ | async)" class="alert">
            <ng-container *ngIf="selectedItems === null">
                * Selecciona una misión
                <ng-container *ngIf="!(hasSelected$ | async)">
                    y al menos una imagen.
                </ng-container>
            </ng-container>
            <ng-container *ngIf="selectedItems !== null && !(hasSelected$ | async)">
                * Selecciona al menos una imagen.
            </ng-container>
        </span>
    </div>
</section>
<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center" [selectedIndex]="activeTabIndex"
    (selectedIndexChange)="onTabChange($event)">
    <mat-tab label="Fotos">
        @if(images$ | async; as data){
        @if(data.length === 0){
        <div class="no-images">
            <mat-icon>perm_media</mat-icon>
            <span>Aún no tienes imágenes. ¡Toma una foto para empezar!</span>
        </div>
        }
        <form [formGroup]="imagesForm" class="grid-container">
            <div *ngFor="let image of images$ | async" class="image-container"
                [class.selected]="selectedImages.includes(image?.id ?? 0)">
                <img class="img-grid" [src]="image.url" [alt]="image.filename" />
                <div class="btns-actions">
                    <mat-checkbox color="primary" [formControlName]="image.id?.toString() ?? ''"
                        class="check"></mat-checkbox>
                    <button mat-icon-button (click)="openBottomConfirmDelete(image)">
                        <mat-icon class="material-symbols-outlined">delete</mat-icon>
                    </button>
                </div>
            </div>
        </form>
        }
    </mat-tab>
    <mat-tab label="Historial" [ngClass]="'historial-tab'">
        <div class="task-content">
            @if(recognitions$ | async){

            <div class="card-recon" *ngFor="let recognition of groupedRecognitions$ | async">
                <a [routerLink]="['..','details',recognition.recognition_id]">
                    {{recognition.task_name}}
                </a>
                <a *ngIf="!recognition.task_name" [routerLink]="['..','details',recognition.recognition_id]">
                    {{recognition.recognition_id}}
                </a>
                <span class="key">{{recognition.task_key}}</span>
                <span class="key">{{recognition.recognition_id}}</span>
                <div class="chips">
                    <span *ngIf="recognition.images">
                        <mat-icon class="material-symbols-outlined"> image </mat-icon>
                        {{recognition.images?.length}}
                        {{recognition.images?.length === 1 ? 'Foto' : 'Fotos'}}
                    </span>
                    <span *ngIf="recognition.updated_at">
                        <mat-icon class="material-symbols-outlined"> calendar_month </mat-icon>
                        {{recognition.updated_at | date:'dd/MM/yyyy'}}
                    </span>
                </div>
            </div>
            }
        </div>
    </mat-tab>
</mat-tab-group>
<div class="bts">
    <mat-progress-bar style="position: absolute; top: 0;" *ngIf="upload_eventss().progress > 0"
        [mode]="upload_eventss().progress === 100 ? 'indeterminate' : 'determinate'"
        [value]="upload_eventss().progress">
    </mat-progress-bar>
    <button mat-icon-button (click)="selectFile()" [disabled]="upload_eventss().state == 'uploading'">
        <mat-icon class="material-symbols-outlined"> add_photo_alternate</mat-icon>
    </button>
    <button mat-icon-button (click)="openCamera()" [disabled]="upload_eventss().state == 'uploading'">
        <mat-icon class="material-symbols-outlined">photo_camera</mat-icon>
    </button>
    <input type="file" #fileInput (change)="captureImage($event)" accept="image/jpeg, image/png"
        style="display: none;" />
    <button mat-flat-button color="primary" (click)="run()"
        [disabled]="upload_eventss().state == 'uploading' || selectedItems === null">
        {{upload_eventss().message}}
        @if(upload_eventss().state == 'uploading'){ {{upload_eventss().progress}}% }
    </button>
</div>