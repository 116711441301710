import { ChangeDetectorRef, Component, signal } from '@angular/core';
import { Clients } from '../models/clients';
import { Dashboards } from '../models/dashboards';
import { BehaviorSubject, map, Observable, startWith, Subject } from 'rxjs';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../users/users.service';
import { ClientsService } from '../services/clients.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DashboardsService } from '../services/dashboards.service';
import { SendRecognitionBottomsheetComponent } from '../components/shared/send-recognition-bottomsheet/send-recognition-bottomsheet.component';
import { CommonModule } from '@angular/common';
import { MatNativeDateModule, MatOption } from '@angular/material/core';
import { MatLabel } from '@angular/material/form-field';
import { MainHeaderComponent } from '../components/shared/main-header/main-header.component';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { Users } from '../models/user';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { index, timeFormat } from 'd3';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';


interface Role {
  uid: string;
  role: string;
}
@Component({
  selector: 'app-dashboards',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, MatOption, MatLabel, MainHeaderComponent, MatInputModule, MatButtonModule, MatIconModule, MatSelectModule, MatChipsModule, MatAutocompleteModule, MatNativeDateModule, MatDatepickerModule, MatCheckboxModule,MatTooltipModule],
  templateUrl: './dashboards.component.html',
  styleUrl: './dashboards.component.css',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DashboardsComponent {
  readonly panelOpenState = signal(false);
  oculto = false;
  boardUid!: string;
  boardData: any;
  showConfig = false;
  clients: Clients[] = []
  roleSearchCtrl = new FormControl('');
  filteredUsers: Observable<any[]> = new Observable();
  users: Users[] = [];
  pickers: any[] = [];


  private default_boards: Partial<Dashboards> = {
    "client_id": 1,
    "dashboard_id": " ",
    "name": " ",
    "roles": {},
    "urlBi": " ",
  };

  private board_subject = new BehaviorSubject<Dashboards | Partial<Dashboards> | undefined>(this.default_boards);
  board$: Observable<Dashboards | Partial<Dashboards> | undefined> = this.board_subject.asObservable();


  titleheader: string = 'Dashboard'

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private usersService: UsersService, private boardService: DashboardsService, private clientsService: ClientsService, private router: Router, private _snackBar: MatSnackBar, private _bottomSheet: MatBottomSheet, private cdr: ChangeDetectorRef) { }


  formConfig = [
    { controlName: 'client_id', label: 'Cliente', type: 'select', width: '100%', margin: '' },
    { controlName: 'name', label: 'Nombre del tablero', type: 'text', width: '100%', margin: '' },
    { controlName: 'urlBi', label: 'URL', type: 'text', width: '100%', margin: '' },
    { controlName: 'roles', label: 'Roles', type: '', width: '100%', margin: '' },
  ]

  boardForm: FormGroup = this.fb.group({
    client_id: ['', [Validators.required]],
    name: ['', [Validators.required]],
    roles: this.fb.array([]),
    urlBi: ['', [Validators.required]],
    queries: this.fb.array([])
  })

  ngOnInit(): void {
    this.loadClients();
    this.boardService.loadUsers().subscribe(users => {
      this.users = users;
      this.filteredUsers = this.roleSearchCtrl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || ''))
      );
      this.initializeBoard();
    });
    this.queriesArray.controls.forEach((_, index) => {
      this.pickers[index] = {}
    })
    this.boardForm.valueChanges.subscribe(() => this.for_save = true);
  }

  private _filter(value: string): Users[] {
    const filterValue = value.toLowerCase();
    return this.users.filter(user => user.email.toLowerCase().includes(filterValue));
  }

  get rolesArray(): FormArray {
    return this.boardForm.get('roles') as FormArray;
  }

  addRole(email: string): void {
    const user = this.users.find(u => u.email === email); // Encuentra el usuario por email
    if (user && !this.rolesArray.value.some((role: { uid: string }) => role.uid === user.uid)) {
      // Solo agrega el rol si el usuario existe y no está ya en el array
      this.rolesArray.push(new FormControl({ uid: user.uid, role: 'reader', email: user.email })); // Agregar UID, rol y email
    }
    this.roleSearchCtrl.setValue(''); // Limpiar el input después de la selección
  }



  removeRole(email: string): void {
    const index = this.rolesArray.controls.findIndex((control) => control.value.email === email);
    if (index >= 0) {
      this.rolesArray.removeAt(index);
    }
  }


  get queriesArray(): FormArray {
    return this.boardForm.get('queries') as FormArray;
  }

  addQuery(): void {
    const queryGroup = this.fb.group({
      name: [''],
      host: [''],
      type: [''],
      active:true,
      params: this.fb.group({
        start_date: [null],
        end_date: [null]
      }, { validators: this.validateDateRange }),
    });
    this.queriesArray.push(queryGroup);
  }

  removeQuery(index: number): void {
    this.queriesArray.removeAt(index);
  }

  validateDateRange({ value: { start_date, end_date } }: AbstractControl): ValidationErrors | null {
    return start_date && end_date && new Date(start_date) > new Date(end_date)
      ? { invalidRange: true }
      : null;
  }

  createBoards(boardData: Partial<Dashboards>) {
    this.boardService.createDashboards(boardData).subscribe({
      next: () => {
        this.router.navigate(['/dashboards']);
        this._snackBar.open('Tablero creado.', 'ok');
      },
      error: () => this._snackBar.open('Error al crear el tablero.', 'cerrar')

    });
  }


  saveBoard(): void {
    if (this.boardForm.valid) {
      const { client_id, name, urlBi, queries } = this.boardForm.value;
      const roles = this.rolesArray.value.reduce((acc: { [uid: string]: string }, role: { uid: string; role: string }) => {
        acc[role.uid] = role.role;
        return acc;
      }, {});
      const querie = queries.map((query: any) => ({
        ...query,
        params: {
          start_date: query.params.start_date ? query.params.start_date.toISOString() : null,
          end_date: query.params.end_date ? query.params.end_date.toISOString() : null,
        }
      }))
      const boardData: Partial<Dashboards> = { client_id, name, urlBi, roles, queries: querie };
      this.default_boards = boardData;
      console.log("datos a guardar", boardData)
      // Ejecutar la creación o actualización según corresponda
      if (this.boardUid) {
        this.updateBoard(boardData);
      } else {
        this.createBoards(boardData);
      }

      this.for_save = false;
    } else {
      this.boardForm.markAllAsTouched(); // Marcar todos los campos para mostrar errores si no es válido
    }
  }


  updateBoard(boardData: Partial<Dashboards>) {
    this.boardService.updateDashboard(boardData, this.boardUid).subscribe({
      next: () => {
        this.router.navigate(['/dashboards']);
        this._snackBar.open('Tablero actualizado.', 'ok');
      },
      error: () => this._snackBar.open('Error al actualizar el tablero.', 'cerrar')
    });
  }

  initializeBoard(): void {
    this.boardUid = this.route.snapshot.paramMap.get('uid')!;
    if (this.boardUid) {
      this.titleheader = 'Editando tablero';
      this.creating = false;
      this.refreshBoard();
    } else {
      this.titleheader = 'Creando tablero';
    }
  }
  creating?: boolean = true
  for_save: boolean = false


  recognitionDetails$ = new Subject<any[]>();
  openBottomSheet(): void {
    const bottomSheetRef = this._bottomSheet.open(SendRecognitionBottomsheetComponent, {
      data: this.boardData
    });
  }

  refreshBoard() {
    this.boardService.getDashboardsByUid(this.boardUid).subscribe(board => {
      this.default_boards = board;

      if (board) {
        this.creating = false;
        this.boardForm.patchValue({
          client_id: board.client_id,
          name: board.name,
          urlBi: board.urlBi
        });
        this.rolesArray.clear();

        if (board.roles && typeof board.roles === 'object') {
          Object.entries(board.roles).forEach(([uid, role]) => {
            const user = this.users.find(u => u.uid === uid);
            if (user) {
              this.rolesArray.push(new FormControl({ uid: user.uid, role: role, email: user.email }));
            }
          });
        }

        this.queriesArray.clear();
        if (board.queries && Array.isArray(board.queries)) {
          board.queries.forEach((query: any) => {
            const start_date = this.parseDate(query.params?.start_date);
            const end_date = this.parseDate(query.params?.end_date);
            const queryGroup = this.fb.group({
              name: [query.name || ''],
              host: [query.host || ''],
              type: [query.type],
              active:[query.active],
              params: this.fb.group({
                start_date: [start_date],
                end_date: [end_date]
              })
            }); this.queriesArray.push(queryGroup);
          });
        }
        this.boardData = board;
        this.board_subject.next(this.default_boards);
        this.cdr.detectChanges();
      }
    });
  }

  parseDate(date: any): Date | null {
    if (!date) return null;
    // Si ya es un objeto Date
    if (date instanceof Date) return date;
    // Si es una cadena en formato ISO
    if (typeof date === 'string' && !isNaN(Date.parse(date))) {
      return new Date(date);
    }
    try {
      return new Date(Date.parse(date));
    } catch {
      console.warn(`No se pudo parsear la fecha: ${date}`);
      return null;
    }
  }

  loadClients(): void {
    this.usersService.loadClients().subscribe((clients: Clients[]) => {
      this.clients = clients;
    });
  }

}
