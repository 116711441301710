<div>
    <div class="layout-container">
        <div class="main-page-header">
            <div>
                <button mat-icon-button class="btn-back" (click)="backbutton()">
                    <mat-icon class="material-symbols-outlined" style="margin-right: 4px;">close</mat-icon>
                </button>
            </div>

            <div class="header-info-container">
                <span class="main-header-elem">R-ID: {{this.details().recognition_id}}</span>
                <span class="main-header-elem">MISIÓN: {{this.details().task_key}}</span>
            </div>
            <div class="header-actions-container">
                <button mat-icon-button (click)="onShared()" aria-label="Más información">
                    <mat-icon class="material-symbols-outlined" style="margin-right: 4px;">share</mat-icon>
                </button>
                <button [matMenuTriggerFor]="menu" mat-icon-button>
                    <mat-icon class="material-symbols-outlined" style="margin-right: 4px;">info</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <div class="info-recon">
                        <span>Id reconocimiento</span>
                        <div class="info-id">
                            <p>{{this.details().recognition_id}}</p>
                            <button mat-icon-button (click)="copyIdRecon(this.details().recognition_id)">
                                <mat-icon>content_copy</mat-icon>
                            </button>
                        </div>
                        <div class="timer">
                            <mat-icon>timer</mat-icon>
                            <span>{{ calculateDurationInSeconds(this.details().started_at, this.details().finished_at)
                                }} segundos</span>
                        </div>
                        <div class="info-dates">
                            <div class="info-dates-cont">
                                <span>Inició</span>
                                <span>{{ this.details().started_at | date:'hh:mm:ss.SSS a':'UTC' }}</span>
                                <span>{{ this.details().started_at | date:'yyyy-MM-dd':'UTC' }}</span>
                            </div>
                            <div class="info-dates-cont">
                                <span>Finalizó</span>
                                <span>{{ this.details().finished_at | date:'hh:mm:ss.SSS a':'UTC' }}</span>
                                <span>{{ this.details().finished_at | date:'yyyy-MM-dd':'UTC' }}</span>
                            </div>
                        </div>
                    </div>
                </mat-menu>
            </div>
        </div>
        @if(is_loading){
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        }

        <div class="thumbnail-container">
            <div *ngFor="let image of images(); let i = index" class="thumbnail"
                [class.selected]="selected_image().url === image.url" (click)="selectImage(image)">
                <img [src]="image.url">

                @if(recognition_details()[image.image_id]){
                @if(recognition_details()[image.image_id].veredict){
                <span class="veredict-icon approved"><mat-icon class="material-symbols-outlined" 
                        aria-label="Aprobado" fontIcon="check_circle"></mat-icon></span>
                }@else{
                <span class="veredict-icon refused"><mat-icon class="material-symbols-outlined" 
                        aria-label="No aprobado" fontIcon="cancel"></mat-icon></span>
                }
                }
            </div>
        </div>
        <div id="imageContainer" #imageContainer class="image-container">
            <img [src]="selected_image().url" #imageElement (load)="loadBoundingBoxes()" class="imagep">
            <ng-container *ngFor="let detection of detections() ">

                <div tabindex="0" [id]="detection.id" #tooltip="matTooltip" matTooltipTouchGestures="auto"
                    [matTooltip]="detection.name" matTooltipClass="tooltip-custom" *ngIf="detection.visible"
                    [ngStyle]="getBoxStyle(detection)" class="bounding-box"
                    (click)="showDetails(detection); tooltip.toggle()">
                </div>


            </ng-container>
        </div>
    </div>

    <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel expanded="true">
            <mat-expansion-panel-header class="mat-expansion-panel-header-custom1">
                <mat-panel-title> Mediciones </mat-panel-title>
                <mat-panel-description>
                    <span class="mat-panel-description-item">cant: {{ (this.details().rules || []).length || 0 }}
                    </span>

                    @if(this.details().veredict){

                    <span class="mat-panel-description-item true-chip">Aprobado</span>
                    }@else{
                    <span class="mat-panel-description-item false-chip">Desaprobado</span>

                    }
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="rules-details-container">
                @for(detail of this.details().rules; track $index) {
                <div class="rules-details-item">
                    <mat-icon class="material-symbols-outlined" matListItemIcon
                        [ngClass]="!!detail.approved ? 'false_color':'true_color'">{{
                        detail.approved ? 'check_circle' : 'cancel' }}</mat-icon>
                    <div class="rules-details-description-container">
                        <div class="rules-details-description-header">
                            <span class="gray-color small-font">REGLA: {{detail.rule_id}}</span>
                            <span class="text-aling-right small-font"
                                [ngClass]="!!detail.approved ? 'false_color':'true_color'">

                                @if(detail.approved){
                                Cumple
                                }@else{
                                No cumple
                                }

                            </span>
                        </div>

                        <span class="rules-details-title block">{{detail.rule}}</span>

                    </div>

                </div>

                }
                <mat-divider></mat-divider>

                <div class="rules-details-task-veredict text-aling-right">
                    <span>Resultado general: </span>

                    @if(this.details().veredict){

                    <span class="true-chip">Aprobado</span>
                    }@else{
                    <span class="false-chip">Desaprobado</span>

                    }
                </div>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel expanded="true">
            <mat-expansion-panel-header class="mat-expansion-panel-header-custom1">
                <mat-panel-title> Detecciones </mat-panel-title>
                <mat-panel-description>cant: {{detections().length}}</mat-panel-description>
            </mat-expansion-panel-header>


            @if(detections()){
            <div class="detections-container">
                <div class="detections-container-table-header">
                    <span class="small-font">CANTIDAD </span>
                    <button mat-icon-button (click)="changeAllVisibility();$event.preventDefault()">
                        <mat-icon class="material-symbols-outlined">{{ is_all_visible ? 'visibility' :
                            'visibility_off'
                            }}</mat-icon>
                    </button>
                    <!-- <span class="small-font gray-font">VISIBILIDAD</span> -->
                </div>
                <mat-divider></mat-divider>
                <mat-accordion>
                    <div *ngFor="let detection of grouped_detections() | keyvalue">
                        <mat-expansion-panel class="mat-expansion-panel-custom1" hideToggle>
                            <mat-expansion-panel-header class="mat-expansion-panel-header-custom2">
                                <mat-panel-title>
                                    <span class="ind"
                                        [ngStyle]="{'border-color': 'rgba(' + detection.value.color.r + ',' + detection.value.color.g + ',' + detection.value.color.b + ', 1)'}">
                                        {{detection.value.data.length}}
                                    </span>
                                    {{detection.key}}
                                </mat-panel-title>
                                <button mat-button (click)="toggleVisibility(detection.key);$event.preventDefault()">
                                    <mat-icon class="material-symbols-outlined">{{ detection.value.visible ?
                                        'visibility' : 'visibility_off' }}</mat-icon>
                                </button>
                            </mat-expansion-panel-header>
                            <div class="box-detection" *ngFor="let box of detection.value.data">
                                <div class="scroll-section-btn">
                                    <button mat-button (click)="scrollToSection(box.id)">
                                        ver
                                        <mat-icon class="material-symbols-outlined">
                                            {{ detection.value.visible ? 'visibility' : 'visibility_off' }}
                                        </mat-icon>
                                    </button>
                                    <span class="n-box">Box {{box.id}} </span>
                                    <span [class]="box.score > (0.5) ? 'scorein' : 'scoreout'">SCORE: {{box.score |
                                        fixedTwoDecimals}}
                                    </span>
                                </div>
                                <div>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Modelo</th>
                                                <th>Categoría</th>
                                                <th>Marca</th>
                                                <th>Compañía</th>
                                                <th>Tamaño</th>
                                                <th>Forma</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{{ box.model_id }}</td>
                                                <td>{{ box.category }}</td>
                                                <td>{{ box.brand }}</td>
                                                <td>{{ box.company }}</td>
                                                <td>{{ box.size }}</td>
                                                <td>{{ box.shape }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </div>
                </mat-accordion>
            </div>

            }@else{
            No se encontraron detecciones en la imagen
            }


        </mat-expansion-panel>
    </mat-accordion>
</div>