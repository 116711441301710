import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, ElementRef, inject, signal, ViewChild } from '@angular/core';
import { RecognitionSystemLibraryService } from '../../recongition-system-api/recongnition-library';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ImageRecognitionExtend, RecognitionDetails } from '../../recongition-system-api/model';
import { ImageAnnotatorService } from '../../services/image_anotate.service';
import { FixedTwoDecimalsPipe } from '../../pipes/twodecimal.pipe';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NavigationTrackerService } from '../../services/navigation_tracker.service';
import { Title } from '@angular/platform-browser';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';


type Key = string | number | symbol;
interface DetectionsData {
  data: any[]
  color: {
    r: string
    g: string
    b: string
  }
  visible: boolean
}
@Component({
  selector: 'app-recognition-details',
  standalone: true,
  imports: [CommonModule, FixedTwoDecimalsPipe, MatButtonModule, MatIconModule, MatListModule, MatExpansionModule, ScrollingModule, MatTooltipModule, MatMenuModule, MatProgressBarModule],
  templateUrl: './recognition-details.component.html',
  styleUrl: './recognition-details.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecognitionDetailsComponent {
  _recognition_details = signal({} as { [key: string]: RecognitionDetails });
  readonly recognition_details = computed(() => this._recognition_details())

  _selected_image = signal({} as ImageRecognitionExtend)
  selected_image = computed(() => this._selected_image())

  _details = signal({} as RecognitionDetails)
  details = computed(() => this._details())

  _counters = signal({} as RecognitionDetails[])
  counters = computed(() => this._counters())

  _detections = signal([] as any[])
  detections = computed(() => this._detections())

  _grouped_detections = signal({} as { [key: Key]: DetectionsData })
  grouped_detections = computed(() => this._grouped_detections())

  _images = signal([] as ImageRecognitionExtend[])
  images = computed(() => this._images())

  is_loading: boolean = true
  is_all_visible: boolean = true
  recognition_id!: string
  titleheader: string = 'Playground'
  requestId!: string;
  imageUrl!: string;
  panelVisible = false;
  currentIndex: number = 0;
  thumbnails: string[] = [];
  nameImage!: string
  windowWidth: number = window.innerWidth;


  snackBar = inject(MatSnackBar)
  readonly dialog = inject(MatDialog);


  @ViewChild('imageElement') imageElement!: ElementRef<HTMLImageElement>;

  constructor(
    private recognition: RecognitionSystemLibraryService,
    private route: ActivatedRoute,
    private router: Router,
    private imageAnnotatorService: ImageAnnotatorService,
    private navigation_service: NavigationTrackerService,
    private title: Title
  ) {
    this.title.setTitle('Playground: Resultados de inferencia')

    this.recognition_id = this.route.snapshot.params['id']





    this.recognition.getRecognitionDetailsLive(this.recognition_id).subscribe((payload) => {
      if (payload.length > 0) {

        console.log('payload', payload)
        const details = payload.reduce((result: Record<string, RecognitionDetails>, item: RecognitionDetails) => {
          if (item.image.image_id) { // Asegúrate de que la clave image_id existe
            result[item.image.image_id] = item;
          }
          return result;
        }, {});
        this._recognition_details.set(details)

        if (this.images().length) {
          this.loadBoundingBoxes()
        }
       
      }
    })

    this.recognition.getRecognitionImagesWithImagesLive(this.route.snapshot.params['id']).subscribe(images => {
      if (images.length) {
        console.log('images resutl', images)
        this._images.set(images)
        this._selected_image.set(images[0])
      }
    })
  }

  ngOnInit() { }

  copyIdRecon(id: string) {
    navigator.clipboard.writeText(id).then(() => { this.snackBar.open('Id copiado con éxito.', 'ok', { duration: 2000 }) })
  }

  countElementsInGroups<T>(groupedData: Record<Key, T[]>): Record<Key, number> {
    return Object.entries(groupedData).reduce((result, [key, value]) => {
      result[key] = value.length; // Calcula la cantidad de elementos en cada grupo
      return result;
    }, {} as Record<Key, number>);
  }

  changeAllVisibility() {
    const visible = this.is_all_visible ? false : true
    this.is_all_visible = this.is_all_visible ? false : true

    this._detections.update(current_value => {
      current_value.forEach((v, index) => {
        current_value[index].visible = visible
      })

      return current_value
    })


    this._grouped_detections.update(current_value => {

      Object.keys(current_value).forEach(key => {
        current_value[key] = {
          ...current_value[key],
          visible: visible,
          data: current_value[key].data.map(value => {
            return { ...value, visible: visible }
          })
        }
      })

      return current_value
    })
  }

  scrollToSection(sectionId: string): void {
    const element_container = document.getElementById('imageContainer');
    const element = document.getElementById(sectionId);
    if (element) {

      element_container?.scrollIntoView({ behavior: 'smooth', block: 'start' });


      setTimeout(() => {
        element.focus();
        element.dispatchEvent(new Event('touchstart'))
        element.dispatchEvent(new Event('mouseenter'));
      }, 500);
    }
  }

  toggleVisibility(key: string) {
    this._detections.update(current_value => {
      current_value.forEach((v, index) => {
        if (key === v.name) {
          current_value[index].visible = current_value[index].visible ? false : true
        }
      })
      return current_value
    })

    this._grouped_detections.update(current_value => {
      current_value[key] = {
        visible: current_value[key].visible ? false : true,
        color: current_value[key].color,
        data: current_value[key].data.map(value => {
          return { ...value, visible: value.visible ? false : true }
        })
      }
      return current_value
    })
  }



  togglePanel() {

    // this.panelVisible = !this.panelVisible;
    // setTimeout(() => {
    //   this.imageElement.nativeElement.style.width = '100%';
    //   // this.setupBoundingBoxes();
    // }, 300);
  }

  clearThumbnails() {
    this.thumbnails.forEach(url => URL.revokeObjectURL(url));
    this.thumbnails = [];
  }

  selectImage(image: ImageRecognitionExtend) {
    this._selected_image.set(image)
    this.loadBoundingBoxes()
  }

  showDetails(box: any): void {
    // console.log('Detalles:', box);
  }


  getBoxStyle(box: any): any {
    const imgWidth = this.imageElement.nativeElement.clientWidth;
    const imgHeight = this.imageElement.nativeElement.clientHeight;
    const naturalWidth = this.imageElement.nativeElement.naturalWidth;
    const naturalHeight = this.imageElement.nativeElement.naturalHeight;

    const scaleX = imgWidth / naturalWidth;
    const scaleY = imgHeight / naturalHeight;
    const { r, g, b } = box.color;
    const border_style = box.is_other ? 'dashed' : 'solid'

    const result =
    {
      position: 'absolute',
      //  'border': '2px solid ' + box.color,
      left: box.bounding_box.x_min * scaleX + 'px',
      top: box.bounding_box.y_min * scaleY + 'px',
      width: (box.bounding_box.x_max - box.bounding_box.x_min) * scaleX + 'px',
      height: (box.bounding_box.y_max - box.bounding_box.y_min) * scaleY + 'px',
      '--box-color': `${r}, ${g}, ${b}`,
      'border': `2px ${border_style} rgba(${r}, ${g}, ${b}, 1)`,
    };
    return result
  }

  groupDetectionsBy<T>(array: T[], key: keyof T): Record<Key, any> {
    return array.reduce((result, item: any) => {
      const groupKey = item[key] as Key;
      if (!result[groupKey]) result[groupKey] = {}
      if (!result[groupKey].data) result[groupKey].data = []

      result[groupKey].data.push(item);
      result[groupKey].color = item.color
      result[groupKey].visible = true
      return result;
    }, {} as Record<Key, any>);
  }

  loadBoundingBoxes() {
    const value = this.recognition_details()[this.selected_image().image_id]

    // const detections = value?.inferences?.sort((a, b) => a.name.localeCompare(b.name))
    const detections = value?.inferences?.sort((a, b) => {
      const areaA = a.bounding_box.width * a.bounding_box.height;
      const areaB = b.bounding_box.width * b.bounding_box.height;
      return areaB - areaA; // Ordena de menor a mayor
    })
      .map((det, index) => {
        const cleanedName = det.name.trim();
        const is_other = cleanedName.split('_').slice(-1)[0].toLowerCase() === "others" ? true : false
        return {
          ...det,
          id: index,
          visible: true,
          color: this.imageAnnotatorService.generateColorFromName(cleanedName, is_other),
          is_other: cleanedName.split('_').slice(-1)[0].toLowerCase() === "others" ? true : false
        }
      });

    const grouped_detections = this.groupDetectionsBy<any>(detections, 'name')
    // const counts  = this.countElementsInGroups(grouped_detections)


    this._details.set(value || [])
    this._detections.set(detections || {})
    this._grouped_detections.set(grouped_detections)
    this.is_loading = false

    // console.log(grouped_detections, counts)
  }


  backbutton() {
    this.router.navigateByUrl(this.navigation_service.previousUrl || '/playground/task');
  }

  onShared() {

    const url = window.location.href;

    if (!navigator.share) {
      this.copyToClipboard()
    } else {
      this.shareUrl()
    }

  }

  copyToClipboard(): void {
    const url = window.location.href;
    navigator.clipboard.writeText(url).then(
      () => {
        console.log('URL copiada al portapapeles:', url);
        alert('¡URL copiada al portapapeles!');
      },
      err => {
        console.error('Error al copiar la URL:', err);
        alert('No se pudo copiar la URL. Intenta nuevamente.');
      }
    );
  }

  shareUrl(): void {
    const url = window.location.href;
    if (navigator.share) {
      navigator.share({
        title: '¡Mira tus resultados!',
        text: `Encuentra aquí los resultados de tu reconocimiento: ${this.details().recognition_id}`,
        url: url,
      })
        .then(() => console.log('URL compartida exitosamente.'))
        .catch(err => console.error('Error al compartir:', err));
    } else {
      alert('La función de compartir no está disponible en este dispositivo.');
    }
  }

  calculateDurationInSeconds(startedAt: Date, finishedAt: Date): number {
    const start = new Date(startedAt).getTime();
    const finish = new Date(finishedAt).getTime();
    return (finish - start) / 1000;
  }


}
