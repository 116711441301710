import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RecognitionSystemLibraryService } from '../../../recongition-system-api/recongnition-library';
@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.css'],
  standalone: true,
  imports: [MatButtonModule, MatIconModule]
})
export class DialogConfirmComponent {
  constructor(
    public system_recognition: RecognitionSystemLibraryService,
    private _bottomSheetRef: MatBottomSheetRef<DialogConfirmComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { image: any }
  ) {
    // console.log('data', data)
  }
  deleting: boolean = false
  confirm(): void {
    this.deleting = true
    this._bottomSheetRef.dismiss();
    this.system_recognition.deleteImage(this.data.image.id).subscribe({
      next: () => {
        this._bottomSheetRef.dismiss();
      },
      error: (error) => {
        this.deleting = false;
      },
    });
  }

  close(): void { this._bottomSheetRef.dismiss(); }

}
