import { computed, inject, Injectable, signal } from '@angular/core';
import { Firestore, collection, collectionData, query, where } from '@angular/fire/firestore';
import { Auth } from '@angular/fire/auth';
import { defer, from, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    firestore: Firestore = inject(Firestore);
    auth = inject(Auth)
    http = inject(HttpClient);

    private selectedDashboardUrl = signal<any | null>(null);
    setSelectedDashboardUrl(d: any) {
        this.selectedDashboardUrl.set(d);
    }

    get selectedDashboardUrlSignal() {
        return this.selectedDashboardUrl();
    }
    granular$ = computed(() => {
        const dashboard = this.selectedDashboardUrl();
        return dashboard && dashboard.queries
            ? dashboard.queries.filter((query: any) => query.type === 'granular')
            : [];
    });

    gestion$ = computed(() => {
        const dashboard = this.selectedDashboardUrl();
        return dashboard && dashboard.queries
            ? dashboard.queries.filter((query: any) => query.type === 'gestion')
            : [];
    });

    images$ = computed(() => {
        const dashboard = this.selectedDashboardUrl();
        return dashboard && dashboard.queries
            ? dashboard.queries.filter((query: any) => query.type === 'images')
            : [];
    });


    getDashboards(): Observable<any> {
        return defer(() => {
            const currentUser = this.auth.currentUser;
            if (currentUser) { return of(currentUser) } else {
                return of(null);
            }
        }).pipe(
            switchMap(user => {
                const userId = user?.uid;
                const dashboardsRef = collection(this.firestore, 'dashboards');
                const q = query(dashboardsRef, where(`roles.${userId}`, '==', 'reader'));
                return collectionData(q, { idField: 'id' });
            })
        );
    }
    downloadFile(url: string): Observable<Blob> {
        return this.http.get(url, { responseType: 'blob' });
    }


}
