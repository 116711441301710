<app-main-header [name_main]="titleheader" [id_code]="ruleId">
  @if(creating){
  <button [disabled]="for_save || !ruleForm.valid"  mat-flat-button color="primary" (click)="onSubmit()">Guardar</button>
  }@else {
  <button mat-flat-button color="primary" (click)="onClone()" class="btn">Duplicar</button>
  <button mat-flat-button color="primary" [disabled]="!ruleForm.valid" (click)="onEdit()" class="btn">Actualizar</button>
  }
</app-main-header>
<form [formGroup]="ruleForm">
  <div class="rule-data">
    @for (con of formConfig; track rulesparameters.length; let idx = $index, e = $even) {

    <mat-form-field appearance="outline" [ngStyle]="{ 'width': con.width }">
      <mat-label>{{ con.label }}</mat-label>
      <input matInput [formControlName]="con.controlName" [type]="con.type">
    </mat-form-field>
    }
  </div>


  <div class="rule-parameters">
    <div class="section-head">
      <span>Parámetros</span>
      <button mat-flat-button color="primary" (click)="addParameter()" >Añadir
        nuevo</button>
    </div>

    @for (param of rulesparameters.value; track param.trackingId; let idx = $index, e = $even) {
    <!-- {{param | json}} -->
    <div formArrayName="rulesparameters" class="form-row">
      <ng-container [formGroupName]="idx">
        <mat-form-field appearance="outline">
          <mat-label>Nombre visible</mat-label>
          <input matInput formControlName="key">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Tipo</mat-label>
          <input matInput formControlName="format">
        </mat-form-field>
      </ng-container>
      <button mat-icon-button (click)="removeParameter(idx)">
        <span class="material-symbols-outlined">delete</span>
      </button>
    </div>
    }
  </div>
  <br>


  <div class="rule-parameters">
    <div class="section-head">
      <span>Salidas</span>
      <button mat-flat-button color="primary" (click)="addOuput()">Añadir nuevo</button>
    </div>


    @for (param of ruleoutputs.value; track param.trackingId; let idx = $index, e = $even) {
    <!-- {{param | json}} -->
    <div formArrayName="ruleoutputs" class="form-row">
      <ng-container [formGroupName]="idx">
        <mat-form-field appearance="outline">
          <mat-label>Valor</mat-label>
          <input matInput formControlName="key">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Nombre visible</mat-label>
          <input matInput formControlName="label">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Tipo</mat-label>
          <input matInput formControlName="type">
        </mat-form-field>
      </ng-container>
      <button mat-icon-button (click)="removeOuput(idx)">
        <span class="material-symbols-outlined">delete</span>
      </button>
    </div>
    }
  </div>
</form>

