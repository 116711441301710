import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpHeaders, HttpParams } from '@angular/common/http';
import { effect, Injectable, signal } from "@angular/core";
import { catchError, concatMap, delay, exhaustMap, filter, forkJoin, from, iif, interval, map, mergeMap, Observable, of, Subject, switchMap, takeUntil, takeWhile, tap, throwError, timeout, timer } from "rxjs";
import { environment } from '../../environments/environment';
import { RecognitionResponse, ClientRecognitionState, RequestPayload, RecognitionDetails, Images, ImageRecognitionExtend, Image, ImageRecognition } from './model';
import { db } from './db';

@Injectable({
    providedIn: 'root'
})
export class RecognitionSystemLibraryService {
    private token_url = `${environment.api_recognition}/auth/token?client_id=${environment.client}`;
    private localStorageKey = 'At_';
    private apiUrlRecon = `${environment['api_recognition']}/v2/recognition`
    private apiUrlDetails = `${environment['api_recognition']}/v2/recognition/details`
    private api_url_images = `${environment['api_recognition']}/v2/image`

    constructor(private http: HttpClient) {
    }

    private fetchToken(): Observable<string | null> {
        const headers = new HttpHeaders({
            'accept': 'application/json',
            'Authorization': environment.key
        });
        return this.http.get<{ token: string }>(this.token_url, { headers }).pipe(
            tap(response => console.log('Token fetched:', response)),
            map(response => response.token),
            catchError(error => {
                console.error('Error fetching token:', error);
                return of(null);
            })
        );
    }

    private checkToken(): Observable<string | null> {
        // console.log('checkToken')
        const token = localStorage.getItem(this.localStorageKey);
        if (token) { return of(token) } else {
            return this.fetchToken().pipe(
                tap(newToken => {
                    if (newToken) {
                        localStorage.setItem(this.localStorageKey, newToken);
                    }
                })
            );
        }
    }

    private getHeaders(): Observable<HttpHeaders> {
        return this.checkToken().pipe(
            map(token => new HttpHeaders({
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json'
            }))
        );
    }

    public sendRecognition(payload: RequestPayload): Observable<HttpEvent<Object>> {
        const formData: FormData = new FormData();
        for (let file of payload.files) {
            formData.append('files', file.blob_data, file.filename);
        }
        formData.append('task_id', payload.task_id);
        formData.append('metadata', JSON.stringify(payload.metadata));

        return this.getHeaders().pipe(
            switchMap(headers =>
                this.http.post(this.apiUrlRecon, formData, {
                    headers,
                    reportProgress: true,
                    observe: 'events' // Necesario para capturar el progreso
                }).pipe(
                    catchError((error: HttpErrorResponse) => {
                        if (error.status === 401 || error.status === 403) {
                            localStorage.removeItem(this.localStorageKey);
                            return this.getHeaders().pipe(
                                switchMap(newHeaders =>
                                    this.http.post(this.apiUrlRecon, formData, {
                                        headers: newHeaders,
                                        reportProgress: true,
                                        observe: 'events'
                                    })
                                )
                            );
                        } else if (error.status === 500) {
                            console.error('Token expired or server error');
                            return throwError(() => new Error('Token expired or server error'));
                        } else if (error.status === 0) {
                            console.error('Network error or request stalled. Please check your connection.');
                            return throwError(() => new Error('Network error or request stalled'));
                        } else {
                            return throwError(() => error);
                        }
                    })

                )
            )
        );
    }

    public getRecognitionDetailsLive(recognition_id: string) {
        this.loadDetails(recognition_id).subscribe()
        return db.getRecognitionDetailsLive(recognition_id)
    }

    private async getRecognitionImagesWithImages(images_payload: RecognitionDetails[]) {
        const recognition_images = await db.getImageRecognitions('image_id', images_payload.map(r => r.image.image_id))
        const local_image_ids = recognition_images.map(i => i.local_image_id)
        const images = await db.getImagesByIds(local_image_ids)
        // console.log('images', images)
        return images
    }

    public loadDetails(recognition_id: string) {
        let processedEmitted = false
        return interval(500)
            .pipe(
                exhaustMap(() => this.requestRecognitionDetails(`${recognition_id}`)),
                takeWhile(recognition_status => recognition_status.state !== 'finished', true),
                tap(async (payload) => {
                    console.log('recognition_status', payload)
                    if (payload.response.length) {
                        const recognition_images = await this.getRecognitionImagesWithImages(payload.response)
                        if (!recognition_images.length) {
                            console.log('descargar imagenes')
                            this.downloadImages(payload.response.map(i => i.image)).subscribe(downloaded_images => {

                                payload.response.forEach(async (recognition_image) => {
                                    const image_bytes = downloaded_images[recognition_image.image.image_id]
                                    const local_image_id = await db.image.add({
                                        filename: recognition_image.image.original_name,
                                        blob_data: image_bytes
                                    })

                                    await db.addImageRecognitions([{
                                        local_image_id: local_image_id,
                                        image_id: recognition_image.image.image_id,
                                        recognition_id: recognition_image.recognition_id,
                                        file_name: recognition_image.image.original_name
                                    }])
                                })
                            })




                        }
                        console.log('contiene datos', recognition_images)
                    }
                }),
                // filter((recognition_status) => ['finished', 'processed'].includes(recognition_status.state)),
                filter((recognition_status) => {

                    if (recognition_status.state === 'processed' && !processedEmitted) {
                        processedEmitted = true;
                        return true;
                    }
                    return recognition_status.state === 'finished';
                }),
                tap(async (payload) => {
                    console.log('payload', payload)

                    await db.setRecognitionDetails(payload.response)


                })
            )
    }

    private getRecognitionStatus(id: string): Observable<ClientRecognitionState> {
        return this.getHeaders().pipe(
            switchMap(headers => this.http.get<ClientRecognitionState>(`${this.apiUrlRecon}/status/${id}`, { headers }).pipe(
                catchError((error: HttpErrorResponse) => {
                    if (error.status === 401 || error.status === 403) {
                        localStorage.removeItem(this.localStorageKey);
                        return this.getHeaders().pipe(
                            switchMap(newHeaders => this.http.get<ClientRecognitionState>(`${this.apiUrlRecon}/status/${id}`, { headers: newHeaders }))
                        );
                    } else {
                        return throwError(() => error);
                    }
                })
            ))
        );
    }

    private requestRecognitionDetails(id: string): Observable<ClientRecognitionState> {
        return this.getHeaders().pipe(
            switchMap(headers => this.http.get<ClientRecognitionState>(`${this.apiUrlDetails}/${id}`, { headers }).pipe(
                catchError((error: HttpErrorResponse) => {
                    console.log('error', error)
                    if (error.status === 401 || error.status === 403) {
                        localStorage.removeItem(this.localStorageKey);
                        return this.getHeaders().pipe(
                            switchMap(newHeaders => this.http.get<ClientRecognitionState>(`${this.apiUrlDetails}/${id}`, { headers: newHeaders }))
                        );
                    } else {
                        return throwError(() => error);
                    }
                })
            ))
        );
    }

    public getImagesByRecognitionId(recognition_id: string) {
        function createImageURL(blob: Blob): string {
            const url = URL.createObjectURL(blob);
            return url;
        }
        // const images = await db.getImagesRecogitionsByRecognititionId(recognition_id)
        return from(db.getImagesRecogitionsByRecognititionId(recognition_id)).pipe(
            tap((images) => {
                console.log('images_recognition', images)
            }),
            // iif(() => true, from(db.getRecognitionDetailsLive(this.recognition_id)), from(db.getImagesByIdsLive(image_ids))),
            mergeMap((images) => {
                // if (images.length) {
                //     const image_ids = images.map(item => item.local_image_id)
                //     return db.getImagesByIdsLive(image_ids)
                // }else{
                //     return from(db.getRecognitionDetailsLive(recognition_id)).pipe(
                //         map(value =>{

                //         })
                //     )
                // }
                const image_ids = images.map(item => item.local_image_id)
                return db.getImagesByIdsLive(image_ids)
            }),

            map((images) => {
                return images.map(img => {
                    const url = createImageURL(img.blob_data);
                    return { ...img, url }
                })
            })
        )
    }

    private downloadImages(images: Images[]): Observable<any> {
        return this.checkToken().pipe(
            map(token => new HttpHeaders({
                'Authorization': `Bearer ${token}`,
                'Accept': 'image/jpeg'
            })),
            switchMap(headers =>
                forkJoin(
                    images.reduce(
                        (obj, image) => (
                            {
                                ...obj,
                                [image.image_id]: this.http.get(`${this.api_url_images}/download/${image.file_name}`, { headers, 'responseType': 'blob' })
                            }),
                        {})).pipe(
                            catchError((error: HttpErrorResponse) => {

                                return throwError(() => error);

                            })
                        ))
        );

    }

    private transformApiResponse(data: any): any[] {
        let products: any[] = [];
        for (const imageId in data.inferences) {
            if (data.inferences.hasOwnProperty(imageId)) {
                data.inferences[imageId].forEach((item: any) => {
                    products.push({
                        imageId: imageId,
                        name: item.name,
                        score: item.score,
                        bounding_box: {
                            xmin: parseFloat(item.bounding_box.xmin),
                            ymin: parseFloat(item.bounding_box.ymin),
                            xmax: parseFloat(item.bounding_box.xmax),
                            ymax: parseFloat(item.bounding_box.ymax),
                            width: parseFloat(item.bounding_box.xmax) - parseFloat(item.bounding_box.xmin),
                            height: parseFloat(item.bounding_box.ymax) - parseFloat(item.bounding_box.ymin)
                        },
                        path: item.path
                    });
                });
            }
        }
        return products;
    }

    

    public getRecognitionImagesWithImagesLive(recognition_id: string): Observable<ImageRecognitionExtend[]> {

        function createImageURL(blob: Blob): string {
            const url = URL.createObjectURL(blob);
            return url;
        }
        return from(db.getImagesRecogitionsByRecognititionIdLive(recognition_id)).pipe(
            // tap((d) => console.log('getImagesRecogitionsByRecognititionIdLive', d)),
            switchMap(async (images) => {
                const validImages = await Promise.all(
                    images.map(async (item) => {
                        const [img] = await db.getImagesByIds([item.local_image_id]);
                        if (!img) {
                            // console.warn(`Imagen no encontrada para local_image_id: ${item.local_image_id}`);
                            return null;
                        }
                        return { ...item, ...img };
                    })
                );
                return validImages.filter((img) => img !== null);
            }),
            map((images) => {
                return images.map((img) => {
                    const url = createImageURL(img.blob_data);
                    return { ...img, url };
                });
            })
        );

    }

    public getLocalImages() {
        return from(db.getImages()).pipe(map((images) => {
            return images.map(img => {
                const url = URL.createObjectURL(img.blob_data);
                return { ...img, url }
            })
        }));
    }

    public async setImage(image: Image) {
        return db.image.add(image)
    }

    public async getAllRecognitionImages(): Promise<ImageRecognitionExtend[]> {
        const recogntion_images = await db.getAllImageRecognition();
        const imgs = recogntion_images.map(async (item) => {
            const [img] = await db.getImagesByIds([item.local_image_id]);
            if (!img) {
                return null;
            }
            return { ...item, ...img };
        });
        const images = await Promise.all(imgs);
        const validImages = images.filter((img) => img !== null);
        return validImages.map((img) => {
            const url = URL.createObjectURL(img.blob_data);
            return { ...img, url };
        });
    }

    public getRecognitionWithDetails(): Observable<any[]> {
        return from(db.getCombinedRecognitionDetails());
    }
    public deleteImage(id: number): Observable<void> {
        return from(db.deleteImageAndRecognition(id));
    }


}