import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { computed, Injectable, signal } from '@angular/core';
import { environment } from '../../environments/environment';
import { CvatTasks } from '../models/tasks_cvat';
import { BehaviorSubject, Observable, throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CvatTasksService {
  private baseUrl: string = `${environment['api_backend']}/api`
  private apiUrlRecon = `${environment['api_recognition']}/v1/recognition`
  private apiUrlDetails = `${environment['api_recognition']}/v2/recognition/details`
    // TOKEN PARA API RECOGNITION
  private headers = new HttpHeaders({
        'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRfdWlkIjoiY2IxNDk2Y2QtOTJhZi00YjE0LWFiZWItMjMwZWUzZGU5ZjA4IiwiY2xpZW50X2lkIjo0LCJpYXQiOjE3MjI0ODIzOTIsImV4cCI6MTcyMjQ5MzE5Mn0.6VEgFrRSx9uHEGWpAMhJViRzxwnOc7EyamkgrhnEojw`,
        'Accept': 'application/json'
    });
    private TasksSignal = signal <CvatTasks[]>([]);
    tasks = computed(() => this.TasksSignal());
    tasks$ = new BehaviorSubject<CvatTasks[]>(this.TasksSignal());

    private handleError(error: any): Observable <never> {
    console.error('An error occurred:', error);
    return throwError(error);
    }

  constructor(private http: HttpClient) {};

  loadTasks(): void {
    let params = new HttpParams().set('limit', '200');
    this.http.get<CvatTasks[]>(`${this.baseUrl}/cvat/tasks`, {params}).subscribe({
      next: (response: any) => {
        const tasks = response.results || []; 
        this.TasksSignal.set(tasks);
        this.tasks$.next(tasks);
        console.log('Datos de la tarea', tasks)
      },
      error: (error) => this.handleError(error),
    });
  }
}
