import { Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { authGuard } from './guards/auth.guard';
import { adminGuard } from './guards/admin.guard';
import { authRedirectGuard } from './guards/auth-true.guard';
import { LayoutComponent } from './components/layout/layout.component';
import { ModelPlayGroundComponent } from './components/playground/model-play-ground/model-play-ground.component';
import { RenderImagesComponent } from './components/playground/render-images/render-images.component';
import { RulesPlayGroundComponent } from './components/playground/rules-play-ground/rules-play-ground.component';
import { TaskPlayGroundComponent } from './components/playground/task-play-ground/task-play-ground.component';
import { ListComponent } from './components/missions/list/list.component';
import { MissionsComponent } from './components/missions/missions.component';
import { ListComponentRules } from './components/rules/list/list.component';
import { RulesComponent } from './components/rules/rules.component';
import { BoardListComponent } from './components/boards/board-list/board-list.component';
import { BoardsComponent } from './components/boards/boards.component';
import { ListClientsComponent } from './components/clients/list-clients/list-clients.component';
import { ClientsComponent } from './components/clients/clients/clients.component';
import { ListLibraryObjectsComponent } from './components/library-objects/list-library-objects/list-library-objects.component';
import { LibraryObjectsComponent } from './components/library-objects/library-objects.component';
import { SigninComponent } from './components/signin/signin.component';
import { ModelsComponent } from './components/models/models.component';
import { ListModelssComponent } from './components/models/list/list.component';
import { ListUsersComponent } from './users/list/list.component';
import { UsersComponent } from './users/users.component';
import { ListBoardComponent } from './dashboards/list-board/list-board.component';
import { DashboardsComponent } from './dashboards/dashboards.component';
import { MissionsCreateComponent } from './components/missions/missions-create/missions-create.component';
import { RecognitionDetailsComponent } from './components/recognition-details/recognition-details.component';
import { CvatTasksComponent } from './components/cvat/cvat-tasks/cvat-tasks.component';

// Importa los demás componentes como tienes en tu configuración

export const routes: Routes = [

    {
        path: '',
        redirectTo: '/boards',
        pathMatch: 'full'
    },
    {
        path: 'boards',
        component: LayoutComponent,
        children: [
            { path: '', component: BoardListComponent },
            { path: ':id', component: BoardsComponent },
        ],
        canActivate: [authGuard]
    },
    {
        path: 'playground',
        component: LayoutComponent,
        children: [
            { path: '', redirectTo: 'models', pathMatch: 'full' },
            {
                path: 'models',
                children: [
                    { path: '', component: ModelPlayGroundComponent },
                    { path: ':id', component: RenderImagesComponent }
                ]
            },
            { path: 'rules', component: RulesPlayGroundComponent },
        ],
        canActivate: [authGuard]
    },
     {
        path: 'playground',
        children: [
            { path: 'task', component: TaskPlayGroundComponent },
            { path: 'details/:id', component: RecognitionDetailsComponent }
        ],
        // canActivate: [authGuard]
    },
    {
        path: 'auth',
        component: SigninComponent,
        canActivate: [authRedirectGuard] // Si está logueado redirige a la a
    },
    {
        path: 'missions',
        component: LayoutComponent,
        children: [
            { path: '', component: ListComponent },
            { path: 'create', component: MissionsCreateComponent},
            { path: ':id', component: MissionsComponent },
        ],                     
        canActivate: [adminGuard, authGuard],
    },
    {
        path: 'rules',
        component: LayoutComponent,
        children: [
            { path: '', component: ListComponentRules },
            { path: 'create', component: RulesComponent },
            { path: ':id', component: RulesComponent },
        ],
        canActivate: [adminGuard, authGuard],
    },
    {
        path: 'models',
        component: LayoutComponent,
        children: [
            { path: '', component: ListModelssComponent },
            { path: ':id', component: ModelsComponent },
        ],
        canActivate: [adminGuard, authGuard],
    },
    {
        path: 'clients',
        component: LayoutComponent,
        children: [
            { path: '', component: ListClientsComponent },
            { path: 'create', component: ClientsComponent },
            { path: ':id', component: ClientsComponent },
        ],
        canActivate: [adminGuard, authGuard],
    },
    {
        path: 'library',
        component: LayoutComponent,
        children: [
            { path: '', component: ListLibraryObjectsComponent },
            { path: 'create', component: LibraryObjectsComponent },
            { path: ':id', component: LibraryObjectsComponent},
        ],
        canActivate: [adminGuard, authGuard],
    },
    {
        path: 'users',
        component: LayoutComponent,
        children: [
            { path: '', component: ListUsersComponent },
            { path: 'create', component: UsersComponent },
            { path: ':uid', component: UsersComponent },
        ],
        canActivate: [adminGuard, authGuard],
    },
    {
        path: 'cvat',
        component: LayoutComponent,
        children: [
            { path: '', component: CvatTasksComponent },
        ],
        canActivate: [adminGuard, authGuard],
    },
    {
        path: 'dashboards',
        component: LayoutComponent,
        children: [
            { path: '', component: ListBoardComponent },
            { path: 'create', component: DashboardsComponent },
            { path: ':uid', component: DashboardsComponent },
        ],
        canActivate: [adminGuard, authGuard],
    }
];
