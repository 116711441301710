import { Component, HostListener, inject } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { WebcamImage, WebcamInitError, WebcamModule, WebcamUtil } from 'ngx-webcam';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';


@Component({
  selector: 'app-dialog-camera',
  standalone: true,
  imports: [FormsModule, CommonModule, WebcamModule, MatIconModule, MatButtonModule],
  templateUrl: './dialog-camera.component.html',
  styleUrl: './dialog-camera.component.css'
})
export class DialogCameraComponent {
  readonly dialogRef = inject(MatDialogRef<DialogCameraComponent>);
  public showWebcam = true;
  public allowCameraSwitch = false;
  public captureImageData = false
  public multipleWebcamsAvailable = false;
  public deviceId!: string;
  public width!: number;
  public height!: number;
  public files: string[] = []
  public show_preview = false
  public current_capture: any

  @HostListener('window:resize', ['$event'])
  onResize(event?: Event) {
    const win = !!event ? (event.target as Window) : window;
    this.width = win.innerHeight;
    this.height = win.innerWidth;
    // this.width = win.innerWidth;
    // this.height = win.innerHeight;
    console.log('medidas', this.width, this.height)
  }

  constructor() {
    
  }
  public videoOptions: MediaTrackConstraints = {
    width: { ideal: 1280},
    height: { ideal: 960 },
    facingMode: 'environment',
    aspectRatio: { ideal: 0.75 }
  };
  public errors: WebcamInitError[] = [];

  // latest snapshot
  public webcamImage: WebcamImage | null = null;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();


  public ngOnInit(): void {
    this.onResize();
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        console.log('mediaDevices', mediaDevices)
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });

    navigator.mediaDevices.getUserMedia({
      video: {
        facingMode: 'environment'
      }
    })
      .then(stream => {
        const track = stream.getVideoTracks()[0];
        const settings = track.getSettings();
        console.log('Resolución soportada:', settings.width, 'x', settings.height);
        console.log('Aspect Ratio:', settings.width, settings.height);
        track.stop(); // Detener el stream cuando no se necesita
      })
      .catch(error => console.error('Error al obtener el stream:', error));
  }
  send() {
    this.dialogRef.close(this.files)
  }
  delete(index: number) {
    this.files.splice(index, 1)
  }

  public close() {
    this.dialogRef.close()
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
    if (error.mediaStreamError && error.mediaStreamError.name === "NotAllowedError") {
      console.warn("Camera access was not allowed by user!");
    }
  }


  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    console.log('change camera')
    this.nextWebcam.next(directionOrDeviceId);

  }

  public approve_image() {
    if (this.webcamImage) {
      if (this.files) {
        this.files.push(this.webcamImage.imageAsDataUrl)
      } else {
        this.files = [this.webcamImage.imageAsDataUrl]
      }

      this.webcamImage = null
    }
    this.show_preview = false
  }
  public refuse_image() {
    this.webcamImage = null
    this.show_preview = false
  }

  public handleImage(webcamImage: WebcamImage): void {
    console.info('received webcam image', webcamImage);
    this.webcamImage = webcamImage;
    this.show_preview = true
  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }
}
