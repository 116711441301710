import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit, OnChanges, SimpleChanges, AfterViewInit, ViewChild, signal } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import * as d3 from 'd3';
import { TableModule } from 'primeng/table';
import { Observable, tap } from 'rxjs';

@Component({
  selector: 'app-image-recognition',
  standalone: true,
  imports: [CommonModule, MatExpansionModule, TableModule],
  templateUrl: './image-recognition.component.html',
  styleUrls: ['./image-recognition.component.css']
})
export class ImageRecognitionComponent implements OnInit, OnChanges, AfterViewInit {
  readonly panelOpenState = signal(false);

  @Input() recognitionResults$!: Observable<any>;

  @ViewChild('image', { static: false }) image!: ElementRef<HTMLImageElement>;
  @ViewChild('svgContainer', { static: false }) svgContainer!: ElementRef<SVGSVGElement>;
  @ViewChild('container', { static: false }) container!: ElementRef<HTMLDivElement>;

  selectedProductIndex = 0;
  originalWidth!: number;
  originalHeight!: number;
  recognitionResultss: any[] = [];

  constructor(private cdr: ChangeDetectorRef, private ngZone: NgZone) { }

  ngOnInit(): void {
    console.log('Component initialized.');
  }

  ngAfterViewInit(): void {
    // console.log('SVG container initialized:', this.svgContainer.nativeElement);
    console.log('Container dimensions:', this.container.nativeElement.clientWidth, this.container.nativeElement.clientHeight);
    this.initializeElements();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['recognitionResults$'] && this.recognitionResults$) {
      // this.subscribeToRecognitionResults();
    }
  }

  private subscribeToRecognitionResults(): void {
    this.recognitionResults$.pipe(
      tap((results) => {
        // console.log('Received results in ngOnChanges:', results);
        this.recognitionResultss = results?.status?.response?.[0]?.results?.inferences || [];
        if (this.recognitionResultss.length > 0) {
          this.ngZone.run(() => {
            this.initializeElements();
            this.cdr.detectChanges();
          });
        } else {
          console.error('Invalid data format received:', results);
        }
      })
    ).subscribe();
  }

  private initializeElements(): void {
    if (this.image && this.container) {
      this.onImageLoad();
    }
  }

  public onImageLoad(): void {
    const img: HTMLImageElement = this.image.nativeElement;
    if (img.complete) {
      this.handleImageLoad(img);
    } else {
      img.onload = () => this.handleImageLoad(img);
      const observer = new MutationObserver(() => {
        if (img.complete) {
          this.handleImageLoad(img);
          observer.disconnect();
        }
      });
      observer.observe(img, { attributes: true });
    }
  }

  private handleImageLoad(img: HTMLImageElement): void {
    console.log('Image loaded:', img);
    this.originalWidth = img.naturalWidth;
    this.originalHeight = img.naturalHeight;
    this.container.nativeElement.style.width = `${img.clientWidth}px`;
    this.container.nativeElement.style.height = `${img.clientHeight}px`;
    this.drawBoundingBoxes();
  }


  private drawBoundingBoxes(): void {
    // console.log('Drawing bounding boxes with results:', this.recognitionResultss);
    if (!this.originalWidth || !this.originalHeight || !this.recognitionResultss.length) {
      console.error('Cannot draw bounding boxes: No valid recognition results or image dimensions.');
      return;
    }

    const svg = d3.select(this.svgContainer.nativeElement);
    const width = this.container.nativeElement.clientWidth;
    const height = this.container.nativeElement.clientHeight;

    svg.selectAll('*').remove();

    this.recognitionResultss.forEach((inference: any, idx: number) => {
      const box = inference.bounding_box;
      const x = (box.x_min / this.originalWidth) * width;
      const y = (box.y_min / this.originalHeight) * height;
      const rectWidth = (box.width / this.originalWidth) * width;
      const rectHeight = (box.height / this.originalHeight) * height;

      svg.append('rect')
        .attr('x', x)
        .attr('y', y)
        .attr('width', rectWidth)
        .attr('height', rectHeight)
        .attr('stroke', 'red')
        .attr('stroke-width', 2)
        .attr('fill', 'none')
        .attr('class', `bounding-box bounding-box-${idx}`);
    });
  }

  public highlightBox(inference: any, index: number): void {
    // console.log('Highlighting box with inference:', inference, 'at index:', index);
    this.selectedProductIndex = index;
    const svg = d3.select(this.svgContainer.nativeElement);

    svg.selectAll('.bounding-box')
      .attr('stroke', 'red')
      .attr('stroke-width', 2)
      .attr('fill', 'none')
      .attr('fill-opacity', 0)
      .style('z-index', 1)
      .classed('selected-bounding-box', false);

    const selectedBox = svg.selectAll(`.bounding-box-${index}`);
    // console.log('Selected bounding box:', selectedBox);

    if (!selectedBox.empty()) {
      selectedBox
        .attr('stroke', 'blue')
        .attr('stroke-width', 2)
        .attr('fill', 'url(#line-pattern)')
        .attr('fill-opacity', 0.5)
        .style('z-index', 9999)
        .classed('selected-bounding-box', true);
    } else {
      console.error(`Bounding box .bounding-box-${index} not found.`);
    }
  }



}
