import { ChangeDetectionStrategy, ChangeDetectorRef, Component, signal } from '@angular/core';
import { Task } from '../../../models/task';
import { BehaviorSubject, firstValueFrom, Observable, skip } from 'rxjs';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MissionsService } from '../../../services/missions.service';
import { ModelService } from '../../models/model.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonModule } from '@angular/common';
import { MainHeaderComponent } from '../../shared/main-header/main-header.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TableModule } from 'primeng/table';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { SearchAutocompleteComponent } from '../../shared/search-autocomplete/search-autocomplete.component';
import { MatSelectModule } from '@angular/material/select';


@Component({
  selector: 'app-missions-create',
  standalone: true,
  imports: [MatDialogModule, CommonModule, MainHeaderComponent, MatInputModule, MatFormFieldModule, FormsModule, ReactiveFormsModule, TableModule, MatIconModule, MatButtonModule, MatMenuModule, MonacoEditorModule, MatSelectModule, RouterLink],
  templateUrl: './missions-create.component.html',
  styleUrl: './missions-create.component.css',
  providers: [MissionsService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissionsCreateComponent {
 readonly panelOpenState = signal(false);
 oculto = false;
 missionId!: number;
 missionData: any;
 assignedRules: any[] = [];
  
  
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private missionsService: MissionsService, public dialog: MatDialog, private _snackBar: MatSnackBar, private router: Router) {
    // const dataa = '{"category":"ORO","channel":"","city":"05001","distributor_id":"114629","distributor_name":"TEST SURTI AWS","id":"8100003153_08450_114629_102_2024-09-18_08:50:24","latitud":"4.7126005","leader":"ZAPATA CEBALLOS JAIME ANDRES","longitud":"-74.215789","neighborhood":"ROBLEDO","region":"MEDELLIN","skus":["8602","4450","4807"],"store_address":"CR 84 63 84","store_key":"8100003153-08450-114629","store_name":"MINIMERCADO LA ANTIOQUEÑITA","uid":"22222222","zone_id":"08450"}';
  }
  private default_task: Partial<Task> = {
     "task_key": undefined,
     "name": '',
     "client_config_id": 1,
     "description": '',
     "required_score": 0,
     "run_type": "",
     "task_label": '',
     tasksrules: [],
     taskmodels: []
   }

   get totalWeight(): number {
    return this.default_task.required_score || 0
  }

  private mission_subject = new BehaviorSubject<Task | Partial<Task> | undefined>(this.default_task);
    mission$: Observable<Task | Partial<Task> | undefined> = this.mission_subject.asObservable();
  
  listModels$!: Observable<any>;
  titleheader: string = 'Misión'

  formConfig = [
    { controlName: 'name', label: 'Nombre misión', type: 'text', width: '100%', margin: '' },
    { controlName: 'description', label: 'Descripción', type: 'textarea', width: '100%', margin: '' },
    { controlName: 'task_key', label: 'Identificador de la misión', type: 'text', width: '100%', margin: '' },
    {
      controlName: 'run_type',
      label: 'Tipo de ejecución',
      type: 'select',
      width: '80%',
      margin: '',
      options: [
        { value: 'realtime', viewValue: 'Tiempo Real' }
      ]
    }, {
      controlName: 'required_score',
      label: 'Peso requerido',
      type: 'number',
      width: '18%',
      margin: '0 0 0 1%'
    }
  ];
  creating?: boolean = true
  missionForm: FormGroup = this.fb.group({
      name: ['', Validators.required],
      description: [''],
      task_key: ['', Validators.required],
      run_type: ['realtime', Validators.required],
      required_score: ['', Validators.required]
    }); 
  
   ngOnInit(): void {
    this.missionId = +this.route.snapshot.paramMap.get('id')!;
    // this.refreshTask();
    this.titleheader = 'Creando Misión';
    console.log('Creando nueva')
    this.missionForm.valueChanges.pipe(skip(1)).subscribe(v => {
      console.log('resultado del form', this.missionForm)
      this.for_save= true
      this.default_task = {...this.default_task, ...v}
      this.mission_subject.next(this.default_task)
      console.log('default_task', this.default_task)
    })
  }

  
    createTask(task: Partial<Task>) {
      const taskData = { ...task, required_score: +task.required_score!, tasksrules: [] };
      this.missionsService.createMission(taskData).subscribe(response => {
        const taskId = response.task_id;
        this.default_task.task_id = taskId;
        this.missionId = taskId
        // if (task.tasksrules?.length) {
        //   const taskRulePromises = task.tasksrules.map((taskRule: any) => {
        //     const taskRuleData: any = {
        //       taskruleparameters: taskRule.taskruleparameters,
        //       taskrulelabel: taskRule.taskrulelabel,
        //       rule_id: taskRule.rule_id,
        //       task_id: taskId
        //     };
        //     return firstValueFrom(
        //       this.missionsService.createTaskRule(taskRuleData)
        //     );
        //   });
        //   Promise.allSettled(taskRulePromises).then(results => {
        //     const failedTasks = results.filter(result => result.status === 'rejected');
        //     if (failedTasks.length > 0) {
        //       console.error(`${failedTasks.length} reglas fallaron al ser creadas.`);
        //     } else {
              
              this._snackBar.open(`Tarea creada.`, 'ok');
              this.router.navigate(['missions', this.missionId])
              // this.refreshTask();
      }
        //   });
        // } else {
        //   this._snackBar.open(`Tarea creada.`, 'ok');
        //   this.router.navigate(['/missions', response]);
        // }
      , error => {
        console.error('Error al crear la tarea:', error);
      });
    }
    
  
  
  saveTask() {
  this.createTask(this.default_task)
  }

  for_save: boolean = false

}