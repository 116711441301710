import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ScreenSizeService {
    private screenWidth = new BehaviorSubject<number>(window.innerWidth);
    screenWidth$ = this.screenWidth.asObservable();
    constructor() {
        window.addEventListener('resize', () => {
            this.screenWidth.next(window.innerWidth);
        });
    }
}
