<app-main-header [name_main]="titleheader">
    @if(isLargeScreen$ | async){
    <section class="btns">
        <button mat-button routerLink="/playground/models" routerLinkActive="activebuttonb">Modelos</button>
        <button mat-button routerLink="/playground/task" [disabled]="false">Tareas</button>
        <!-- <button mat-button routerLink="/playground/rules" [disabled]="true">Reglas</button> -->
    </section>
    }@else{
    <button mat-button mat-stroked-button class="btns-nav" [matMenuTriggerFor]="menu">
        <span class="btns-nav-menu">
            {{ router.url.includes('/task') ? 'Tareas' : 'Módelos' }}
            <span class="material-symbols-outlined" style="margin-right: 0;"> arrow_drop_down </span>
        </span>


    </button>
    <mat-menu #menu="matMenu">
        <button routerLink="/playground/models" mat-menu-item>Módelos</button>
        <button routerLink="/playground/task" mat-menu-item>Tareas</button>
    </mat-menu>
    }
</app-main-header>
<div class="ctn-aut">
    <p-autoComplete id="search-input" [(ngModel)]="selectedItems" (onSelect)="onSelect($event)"
        (onUnselect)="onUnselect($event)" [suggestions]="items" (completeMethod)="search($event)" [multiple]="true"
        field="view" [dropdown]="true" placeholder="Buscar módelo de reconocimiento">
    </p-autoComplete>
</div>
<div>
    <div class="bts">
        <button mat-icon-button (click)="selectFile()">
            <span class="material-symbols-outlined"> add_photo_alternate</span>
        </button>
        <button mat-icon-button *ngIf="hasCamera" (click)="captureFromCamera()">
            <span class="material-symbols-outlined">photo_camera</span>
        </button>
        <input type="file" #fileInput (change)="captureImage($event)" accept="image/jpeg, image/png"
            style="display: none;" />
        <button mat-flat-button color="primary" (click)="sendSelectedImagesToAPI()"
            [disabled]="!selectedItems || selectedItems.length === 0 || images.length === 0 || block">EJECUTAR</button>
        <!-- <button mat-stroked-button (click)="">Show snack-bar</button> -->
        <div class="message-model">
            <!-- {{selectedItems | json}} -->
            @if(selectedItems?.[0]?.name){
            <span>Módelo seleccionado: {{selectedItems?.[0]?.name}}</span>
            }@else {
            <span>Selecciona un módelo de reconocimiento</span>
            }
        </div>
    </div>
    <!-- <div *ngIf="images.length > 0" class="grid-container">
        <ng-container *ngFor="let image of images; let i = index">
            <div class="image-container">
                <img class="img-grid" [src]="imageUrls.get(image.id)" [alt]="image.fileName" />
                <input type="checkbox" [(ngModel)]="image.selected" (click)="uncheckOthers(i)" />

                <button class="del-p" mat-icon-button (click)="deleteImage(image.id)">
                    <span class="material-symbols-outlined">delete</span>
                </button>
            </div>
        </ng-container>
    </div> -->
    @if(images.length > 0){
    <!-- <div *ngIf="images.length > 0">
        <mat-radio-group [(ngModel)]="selectedImageId" (ngModelChange)="onImageSelectionChange()"
            class="grid-container">
            <div *ngFor="let image of images" class="image-container" [class.selected]="image.id === selectedImageId">
                <img class="img-grid" [src]="imageUrls.get(image.id)" [alt]="image.fileName" />
                <mat-radio-button color="primary" [value]="image.id" class="radio-i"></mat-radio-button>
                <button class="del-p" mat-icon-button (click)="deleteImage(image.id)">
                    <span class="material-symbols-outlined">delete</span>
                </button>
            </div>
        </mat-radio-group>
    </div> -->

    <form [formGroup]="imagesForm" class="grid-container">
        <div *ngFor="let image of images" class="image-container" [class.selected]="selectedImages.includes(image.id)">
            <img class="img-grid" [src]="imageUrls.get(image.id)" [alt]="image.fileName" />
            <div class="btns-actions">
                <!-- Checkbox vinculado al FormControl basado en el id de la imagen -->
                <mat-checkbox color="primary" [formControlName]="image.id.toString()" class="check"></mat-checkbox>

                <button mat-icon-button (click)="deleteImage(image.id)">
                    <span class="material-symbols-outlined">delete</span>
                </button>
            </div>

        </div>
    </form>

    <!-- {{imagesForm.value | json}} -->



    }@else {
    No hay imagenes para analizar
    }


</div>