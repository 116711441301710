import { Component, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { CvatTasks } from '../../../models/tasks_cvat';
import { Table } from 'primeng/table';
import { CvatTasksService } from '../../../services/cvat-tasks.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, RouterLink } from '@angular/router';
import { MainHeaderComponent } from '../../shared/main-header/main-header.component';
import { DataTableComponent } from '../../shared/data-table/data-table.component';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-cvat-tasks',
  standalone: true,
  imports: [MainHeaderComponent, DataTableComponent, RouterLink, MatButtonModule, CommonModule, MatFormFieldModule],
  templateUrl: './cvat-tasks.component.html',
  styleUrl: './cvat-tasks.component.css'
})
export class CvatTasksComponent {
tasks$!: Observable<CvatTasks[]>
selected!: CvatTasks[];
products!: CvatTasks[];
selectedTasks: CvatTasks[] = [];
selectedItems:any[] = [];

columns: any[] = [
  { field: 'id', header: 'Id', filter: true, orderby: true },
  { field: 'name', header: 'Nombre', filter: true, orderby: true },
  { field: 'project_id', header: 'Proyecto', filter: true, orderby: true },
]

@ViewChild('dt')table!: Table;
constructor(public cvatService: CvatTasksService, private _snackBar: MatSnackBar, private router: Router) {}

ngOnInit(): void{
  this.cvatService.loadTasks()
}

onSelectionChange(event: any){
  console.log('event', event)
  this.selectedTasks = event
}

onDeleteItems(items: any) {
  if(window.confirm("Esto eliminará este cliente y todas ss configuraciones")) {
  
  }
} 

 deleteClient( tasks: CvatTasks[]) {
    //  this._snackBar.open('Eliminando...', 'ok');
    // if (window.confirm("Esto eliminará este cliente y todas sus configuraciones")) {
    //     clients.map(clients => {
    //     this.clientsService.deleteClients(clients.client_id).subscribe(() => {
    //       this.selectedProducts = []
    //       console.log('cliente delete')
    //       this._snackBar.open(`Cliente ${clients.client_id} eliminado.`, 'ok');
    //       this.clientsService.loadClientsStore()
    //     })
    //   })
    //   } 
  }

  onRowSelect(event: any){
    console.log('select', this.selectedTasks)
  }
}
