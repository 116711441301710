<app-main-header [name_main]="titleheader"> 
</app-main-header>

<form [formGroup]="missionForm">
    <div class="mission-data">
        @for (d of formConfig; track d.label; let idx = $index, e = $even) {
        <mat-form-field appearance="outline" [ngStyle]="{ 'width': d.width, 'margin':d.margin}">
            <mat-label>{{ d.label }}</mat-label>
            <input *ngIf="d.type != 'select'" matInput [formControlName]="d.controlName" [type]="d.type">
            <mat-select *ngIf="d.type === 'select'" [formControlName]="d.controlName">
                <mat-option *ngFor="let option of d.options" [value]="option.value" disabled="true">{{ option.viewValue
                    }}</mat-option>
            </mat-select>
        </mat-form-field>
        }
    </div> 
    <button mat-flat-button color="primary" (click)="saveTask()">Guardar </button>
</form>