import { Injectable } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class NavigationTrackerService {
    private _previousUrl: string | null = null;
    private _currentUrl: string | null = null;

    constructor(private router: Router) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {  
                this._previousUrl = this._currentUrl;
                this._currentUrl = event.url;
            }
        });
    }

    get previousUrl(): string | null {
        return this._previousUrl;
    }

    get currentUrl(): string | null {
        return this._currentUrl;
    }
}