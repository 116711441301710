<div class="delete-image">
    <mat-icon class="material-symbols-outlined" color="warn">delete_forever</mat-icon>
    <p><b>¿Seguro deseas eliminar esta imagen?</b></p>
    <div class="info-image">
        <img [src]="data.image.url" alt="Imagen a eliminar" width="100" />
        <div>
            <!-- <span>Nombre: {{ data.image.filename }}</span> -->
            <span>Tamaño: {{ data.image.blob_data.size}} bytes</span>
            <span>Formato: {{ data.image.blob_data.type}}</span>
        </div>
    </div>
    <div class="ct-delete-image-btn">
        <button mat-stroked-button [disabled]="deleting" class="delete" (click)="confirm()" color="warn">Eliminar</button>
        <button mat-stroked-button [disabled]="deleting" (click)="close()">Cancelar</button>
    </div>
</div>