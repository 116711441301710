<app-main-header [name_main]="'Tareas cvat'">
    <!-- <button class="check-btn" mat-flat-button routerLink="/clients/create">
        CREAR
    </button> -->
    <button [disabled]="!selectedTasks.length" class="check-btn"  mat-flat-button color="warn"
    (click)="deleteClient(selectedTasks)">
    BORRAR
</button>
</app-main-header>
<app-data-table 
    [data]="cvatService.tasks()" 
    [columns]="columns" 
    [rows]="50" 
    [rowsPerPageOptions]="[10, 25, 50]" 
    (rowSelect)="onRowSelect($event)" 
    [selection]="selectedItems" 
    (selectionChange)="onSelectionChange($event)"
    [sortField]="'cvat'"
    [sortOrder]="-1"
    [showFilters]="true"
    [optional]="false">
</app-data-table> 

