<p-table #dt [value]="data" [rows]="rows" [paginator]="true" [rowsPerPageOptions]="rowsPerPageOptions"
    (onRowSelect)="onRowSelect($event)" [(selection)]="selection" (selectionChange)="onSelectionChange($event)" 
    [sortField]="sortField" [sortOrder]="sortOrder" >
    <ng-template pTemplate="header">
        <tr style="font-size: 12px">
            <th style="width: 4rem"><p-tableHeaderCheckbox /></th>
            <th *ngFor="let col of columns" [pSortableColumn]="col.orderby ? col.field : ''">
                <div class="p-column-header-content">
                    <span>{{col.header}}</span>
                    <ng-container *ngIf="col.orderby">
                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                    </ng-container>
                    <ng-container *ngIf="col.filter && showFilters">
                        <p-columnFilter *ngIf="col.field" type="text" [field]="col.field" display="menu" [placeholder]="'Buscar por ' + col.header + '...'" [matchMode]="'contains'"  />
                    </ng-container>
                </div>
            </th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
        <tr style="font-size: 12px">
            <td><p-tableCheckbox [value]="rowData"></p-tableCheckbox></td>
           <td *ngFor="let col of columns">
  <ng-container *ngIf="col.field && rowData">
    {{ getNestedValue(rowData, col.field) }}
  </ng-container>
</td>
            <td>
                <button mat-icon-button  [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <span class="material-symbols-outlined" *ngIf="optional">more_vert</span>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item  (click)="onEditItem(rowData)">
                        <span class="material-symbols-outlined">edit</span>
                        <span>Editar</span>
                    </button>
                    <button *ngIf="showButton" mat-menu-item (click)="onClone(rowData)">
                        <span class="material-symbols-outlined">content_copy</span>
                        <span>Duplicar</span>
                    </button>

                </mat-menu>
            </td>
        </tr>
    </ng-template>
    <!-- <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="{{columns.length + 2}}">Cargando datos...</td>
        </tr>
    </ng-template> -->
</p-table>
