import Dexie, { liveQuery, Observable, Table } from 'dexie';
import { ClientRecognitionState, Image, ImageRecognition, RecognitionDetails } from './model';



export class APPDB extends Dexie {
    image!: Table<Image, number>
    recognition!: Table<ImageRecognition, number>
    recognition_details!: Table<RecognitionDetails, number>

    constructor() {
        super('workspaceapp');
        this.version(2).stores({
            image: '++id',
            recognition: '++id, recognition_id, file_name, image_id, local_image_id',
            recognition_details: '++id, recognition_id'
        });
    }



    async addImageRecognitions(recognitions: ImageRecognition[]): Promise<number> {
        return this.recognition.bulkAdd(recognitions);
    }
    async addRecognitionDetails(recognitions_details: RecognitionDetails[]): Promise<number> {
        return this.recognition_details.bulkPut(recognitions_details);
    }

    async setRecognitionDetails(recognitions_details: RecognitionDetails[]): Promise<void> {

        await this.transaction('rw', this.recognition_details, async () => {
            const keys = recognitions_details.map(d => d.recognition_id);
            const existingRecords = await this.recognition_details
                .where('recognition_id')
                .anyOf(keys)
                .toArray();

            const recordMap = new Map(
                existingRecords.map(r => [r.image.image_id, r])
            );

            for (const detail of recognitions_details) {
                const key = detail.image.image_id;
                if (recordMap.has(key)) {
                    await this.recognition_details.update(recordMap.get(key)!.id!, { id: recordMap.get(key)!.id!, ...detail });
                } else {
                    console.log('value detail', detail)

                    await this.recognition_details.add(detail);
                }
            }
        });
    }
    async getCombinedRecognitionDetails(): Promise<any[]> {
        const recognitionData = await this.recognition.toArray();
        const recognitionDetailsData = await this.recognition_details.toArray();
        const combinedData = recognitionData.map((recognition) => {
            const details = recognitionDetailsData.find((detail) => detail.recognition_id === recognition.recognition_id);
            const { task_id, task_key, veredict } = details || {};
            return { ...recognition, task_id, task_key, veredict };
        });

        return combinedData;
    }


    getRecognitionDetailsLive(recognition_id: string) {
        return liveQuery(() => this.recognition_details.where('recognition_id').equals(recognition_id).toArray());
    }

    getImagesByIdsLive(ids: number[]) {
        return liveQuery(() => this.image.where('id').anyOf(ids).toArray());
    }
    async getImagesByIds(ids: number[]): Promise<Image[]> {
        return this.image.where('id').anyOf(ids).toArray();
    }
    async getImageRecognition(key: string, value: any) {
        console.log(key, value)
        return this.recognition.where(key).equals(value).toArray();
    }
    async getImageRecognitions(key: string, values: any) {
        console.log(key, values)
        return this.recognition.where(key).anyOf(values).toArray();
    }
    async getImagesRecogitionsByRecognititionId(recognition_id: string): Promise<ImageRecognition[]> {
        return this.recognition.where('recognition_id').equals(recognition_id).toArray();
    }
    getImagesRecogitionsByRecognititionIdLive(recognition_id: string): Observable<ImageRecognition[]> {
        return liveQuery(() => this.recognition.where('recognition_id').equals(recognition_id).toArray());
    }

    getAllImageRecognition(): Promise<ImageRecognition[]> {
        return this.recognition.toArray();
    }
    getImages(): Observable<Image[]> {
        return liveQuery(() => db.image.toArray())
    }
    async deleteImageAndRecognition(localImageId: number): Promise<void> {
        try {
            await this.image.delete(localImageId);
            const recognitions = await this.recognition.where('local_image_id').equals(localImageId).toArray();
            if (recognitions.length > 0) {
                await Promise.all(
                    recognitions.map(async (recognition) => {
                        if (recognition.id !== undefined) {
                            await this.recognition.delete(recognition.id);
                        } else {
                            console.warn(`Recognition sin id válido encontrada:`, recognition);
                        }
                    })
                );
            }
        } catch (error) {
            console.error('Error al eliminar la imagen o el reconocimiento:', error);
        }
    }
}

export const db = new APPDB();