<app-main-header [name_main]="titleheader" [id_code]="libraryId">
  @if(creating){
       
      <button [disabled]="for_save" mat-flat-button color="primary" [disabled]="!libraryForm.valid"
          (click)="savelibraries()">Guardar</button>
      }@else {
      <button class="check-btn" mat-flat-button></button>
      <button mat-flat-button color="primary" [disabled]="!libraryForm.valid"
          (click)="savelibraries()">Guardar</button>
      }
      
</app-main-header>

<hr>
<form [formGroup]="libraryForm">

<div class="library-data" >
 @for (d of formConfig; track d.label; let idx = $index, e = $even) {
  <mat-form-field class="outline"  [ngStyle]="{ 'width': d.width, 'margin':d.margin}">    
      <mat-label>{{ d.label }}</mat-label>
  <input *ngIf="d.type != 'select'" matInput [formControlName]="d.controlName" [type]="d.type" >
       
  </mat-form-field>
  <div *ngIf="libraryForm.get(d.controlName)?.errors?.['minlength'] && libraryForm.get(d.controlName)?.touched" class="alert-danger">
          <i class="material-icons">warning</i>
      <small > El valor ingresado debe ser superior a 2 caracteres. </small>
  </div>
  <div *ngIf="d.controlName === 'thumbnail' && libraryForm.get('thumbnail')?.errors?.['pattern'] && libraryForm.get('thumbnail')?.touched" class="alert-danger">
        <i class="material-icons">warning</i>
        <small>El enlace debe ser una URL válida que comience con http:// o https:// y termine con .com, .co, u otro dominio válido.</small>
  </div>
  }
  <mat-form-field appearance="outline">
    <mat-label>Selecciona la marca</mat-label>
    <mat-select formControlName="libraryBrand" placeholder="Selecciona una marca">
      <mat-option *ngFor="let brand of libraryBrand" [value]="brand.name">
        {{ brand.name }}
      </mat-option>
    </mat-select>

    <mat-error *ngIf="libraryForm.get('libraryBrand')?.hasError('required')">
      Selecciona una marca.
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Selecciona la categoría</mat-label>
    <mat-select formControlName="libraryCategory" placeholder="Selecciona una categoría">
      <!-- Iterar sobre el array de categorías -->
      <mat-option *ngFor="let category of libraryCategory" [value]="category.name">
        {{ category.name }}
      </mat-option>
    </mat-select>
    <!-- Mensaje de error si no se selecciona una categoría y es obligatorio -->
    <mat-error *ngIf="libraryForm.get('libraryCategory')?.hasError('required')">
      Selecciona una categoría.
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Selecciona el ean</mat-label>
    <mat-select formControlName="libraryEan" placeholder="Selecciona un ean">
      <!-- Iterar sobre el array de categorías -->
      <mat-option *ngFor="let ean of libraryEan" [value]="ean.ean">
        {{ ean.ean }}
      </mat-option>
    </mat-select>
    <!-- Mensaje de error si no se selecciona una categoría y es obligatorio -->
    <mat-error *ngIf="libraryForm.get('libraryEan')?.hasError('required')">
      Selecciona un ean.
    </mat-error>
  </mat-form-field>
</div>
<!-- </div> -->
</form>
<div class="mission-parameters">
  <div class="title-btn">
      <div> 
  
  